body {
	font-size: 16px;
	line-height: 1.4;
	font-family: 'Slabo 27px', serif;
}
p {
	margin-bottom: 1rem;
}
h1 {
	font-size: .9rem;
	line-height: 1.4;
	text-transform: uppercase;
}
.title {
	display: block;
	font-size: 0.75rem;
	color: #555;
	color: #9f8b61;
	font-weight: 400;
}
h1, h2, h3, h4 {
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 1rem;
}

a {
	&:visited,
	&:link {
		color: #9f8b61;
		text-decoration: none;
		border-bottom: 1px solid rgba(#9f8b61, 0);
		transition: all .1s linear;
	}
	&:hover {
		border-bottom: 1px solid rgba(#9f8b61, 1);
	}
}

code {
	font-family: "Courier New",Courier,monospace,sans-serif;
	background: #f4f0f0;
	border: 1px solid #d0cbcb;
	color: #333;
	font-size: 11px;
	font-weight: 400;
	padding: .1rem .3rem;
	border-radius: 2px;
}