*{
	box-sizing: border-box;
}

html, body {
	padding: 0;
	margin: 0;
	color: #222;
}

//.enter-element {}
//.entering {
//	.enter-element {
//		opacity: 1;
//		background: red;
//		position: fixed;
//		top: 0; right: 0; bottom: 0; left: 0;
//		z-index: 1;
//	}
//}

.page-section {
	height: 100vh;
	border-bottom: 1px dotted #aaa;
	position: relative;
}
.section-main-header {
	text-align: center;
	position: absolute;
	text-align: center;
	left: calc(50% - 40%);
	top: 50%;
	width: 80%;
	margin: 0 auto;
}
.all-the-fun {
	max-width: 300px;
	width: 80%;
	margin: 0 auto;
	position: relative;
	top: 50vh;
	transform: translateY(calc(-50% - 70px));
	@media screen and (min-width: 700px) {
		transform: translateY(calc(-50% - 50px));
	}
}

.social-icon {
	display: inline-block;
	width: 34px;
	height: 34px;
	fill: #333;
	margin: 5px 0 0 0;
	border-bottom: 0 !important;
	&:hover {
		fill: #9f8b61;
	}
	svg {
		width: 28px;
		height: 28px;
	}
}